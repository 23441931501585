var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Procurar...","type":"text"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addClient()}}},[_vm._v(" Adicionar ")])],1)])],1)],1),_c('b-card-actions',{ref:"loadingCard",attrs:{"no-actions":""}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.getClients,"search-options":{
              enabled: true,
              externalQuery: _vm.searchQuery
            },"select-options":{
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              perPage: _vm.pageLength
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'nomeCliente')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.nomeCliente))])]):(props.column.field === 'action')?_c('span',[_c('span',[(props.row.statusDoCliente === 'Inativo')?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Ativar'),expression:"'Ativar'",modifiers:{"hover":true,"bottom":true}}],on:{"click":function($event){return _vm.showModalActive(props.row.idDoCliente)}}},[_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"EyeIcon"}})],1):_vm._e(),(props.row.statusDoCliente === 'Ativo')?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Inativar'),expression:"'Inativar'",modifiers:{"hover":true,"bottom":true}}],on:{"click":function($event){return _vm.handleSelectedId(props.row.idDoCliente)}}},[_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"EyeOffIcon"}})],1):_vm._e(),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"hover":true,"bottom":true}}],staticClass:"icone",attrs:{"href":'/consultar-cliente/' + props.row.idDoCliente}},[_c('feather-icon',{staticClass:"icone",attrs:{"icon":"FileTextIcon"}})],1),_c('span',{staticClass:"espaco"},[_c('b-dropdown',{staticClass:"espaco",attrs:{"variant":"link","toggle-class":"text-decoration-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25 espaco",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                          name: 'client-edit',
                          params: { id: props.row.idDoCliente }
                        }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Editar")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showModalRemove(props.row.idDoCliente)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Excluir")])],1)],1)],1)])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Mostrando 1 até ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '30']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" registros ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"aria-controls":"clientTable","total-rows":_vm.totalRows,"per-page":_vm.pageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Nenhum registro encontrado! ")])])],1)],1),_c('DeleteModal',{ref:"modal",attrs:{"id":_vm.selectedClientId},on:{"inactivateClient":_vm.updateModal}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }