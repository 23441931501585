<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Tipo (PJ/PF)</label>
          <v-select
            :value="planOptions.value"
            :options="planOptions"
            class="w-100"
            @input="val => $emit('filter', val)"
          >
            <span slot="no-options">
              Opção não encontrada
            </span>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status (Ativo/Inativo)</label>
          <v-select
            :value="statusOptions.value"
            :options="statusOptions"
            class="w-100"
            @input="val => $emit('filterStatus', val)"
          >
            <span slot="no-options">
              Opção não encontrada
            </span>
          </v-select>
        </b-col>

        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :value="statusOptions.value"
            :options="statusOptions"
            class="w-100"
            @input="val => $emit('update:filterStatus', val)"
          />
        </b-col> -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue"
import vSelect from "vue-select"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null
    },
    planFilter: {
      type: [String, null],
      default: null
    },
    statusFilter: {
      type: [String, null],
      default: null
    }
    // roleOptions: {
    //   type: Array,
    //   required: true
    // },
    // planOptions: {
    //   type: Array,
    //   required: true
    // },
    // statusOptions: {
    //   type: Array,
    //   required: true
    // }
  },
  data() {
    return {
      roleOptions: [
        { label: "Admin", value: "admin" },
        { label: "Author", value: "author" },
        { label: "Editor", value: "editor" },
        { label: "Maintainer", value: "maintainer" },
        { label: "Subscriber", value: "subscriber" }
      ],
      planOptions: [
        { label: "Pessoa Física", value: "Física" },
        { label: "Pessoa Jurídica", value: "Jurídica" }
      ],
      statusOptions: [
        { label: "Ativo", value: "Ativo" },
        { label: "Inativo", value: "Inativo" }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
