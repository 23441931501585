<template>
  <b-modal
    id="modal-delete"
    title="Inativar cliente"
    centered
    ok-title="SIM"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    @ok="inactivateClient()"
  >
    <b-card-text>
      Deseja inativar esse cliente?
    </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, VBModal, BCardText } from "bootstrap-vue"
import { onUnmounted } from "@vue/composition-api"
import store from "@/store"
import clietnStoreModule from "./clientStoreModule"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import useJwt from "@/auth/jwt/useJwt"

export default {
  components: {
    BCardText,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    "b-modal": VBModal
  },
  props: {
    id: {
      Type: Number,
      required: true
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-client"

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, clietnStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })
  },
  methods: {
    async inactivateClient() {
      const id = this.id
      const resp = await useJwt
        .inactivate(id)
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Erro ao Inativar cliente, Por favor verifique sua conexão!",
              icon: "ErrorIcon",
              variant: "danger",
              text: catchError.response.status
            }
          })
        })

      if (resp.sucesso) {
        this.MensagemToastInativarCliente("success", "top-center")

        this.$emit("inactivateClient")
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao Inativar cliente, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    },
    MensagemToastInativarCliente(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Cliente Inativado!",
            icon: "EditIcon",
            variant
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style></style>
