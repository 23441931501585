<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <div class="custom-search d-flex justify-content-end">
            <b-col md="6">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Procurar..."
                    type="text"
                    class="d-inline-block"
                  />
                  <!-- <label class="ml-1 mr-1">Pesquisar</label> -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1"
                    variant="primary"
                    @click="addClient()"
                  >
                    Adicionar
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </div>

          <!-- table -->
          <b-card-actions
            ref="loadingCard"
            no-actions
          >
            <vue-good-table
              :columns="columns"
              :rows="getClients"
              :search-options="{
                enabled: true,
                externalQuery: searchQuery
              }"
              :select-options="{
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span v-if="props.column.field === 'nomeCliente'">
                  <span>{{ props.row.nomeCliente }}</span>
                </span>
                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <a
                      v-if="props.row.statusDoCliente === 'Inativo'"
                      v-b-tooltip.hover.bottom="'Ativar'"
                      @click="showModalActive(props.row.idDoCliente)"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="text-body align-middle mr-25"
                      />
                    </a>
                    <a
                      v-if="props.row.statusDoCliente === 'Ativo'"
                      v-b-tooltip.hover.bottom="'Inativar'"
                      @click="handleSelectedId(props.row.idDoCliente)"
                    >
                      <feather-icon
                        icon="EyeOffIcon"
                        class="text-body align-middle mr-25"
                      />
                    </a>
                    <a
                      v-b-tooltip.hover.bottom="'Detalhes'"
                      :href="'/consultar-cliente/' + props.row.idDoCliente"
                      class="icone"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        class="icone"
                      />
                    </a>
                    <span class="espaco">
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        class="espaco"
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25 espaco"
                          />
                        </template>
                        <!-- <b-dropdown-item
                        :to="{
                          name: 'client-detail',
                          params: { id: props.row.idDoCliente }
                        }"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalhes</span>
                      </b-dropdown-item> -->

                        <b-dropdown-item
                          :to="{
                            name: 'client-edit',
                            params: { id: props.row.idDoCliente }
                          }"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Editar</span>
                        </b-dropdown-item>
                        <!-- <b-dropdown-item
                        v-if="props.row.statusDoCliente === 'Inativo'"
                        @click="showModalActive(props.row.idDoCliente)"
                      >
                        <feather-icon icon="EyeIcon" />
                        <span class="align-middle ml-50">Ativar</span>
                      </b-dropdown-item> -->
                        <!-- <b-dropdown-item
                        v-if="props.row.statusDoCliente === 'Ativo'"
                        @click="handleSelectedId(props.row.idDoCliente)"
                      >
                        <feather-icon icon="EyeOffIcon" />
                        <span class="align-middle ml-50">Inativar</span>
                      </b-dropdown-item> -->
                        <b-dropdown-item
                          @click="showModalRemove(props.row.idDoCliente)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Excluir</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Mostrando 1 até
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '30']"
                      class="mx-1"
                      @input="
                        value => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      de {{ props.total }} registros
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      aria-controls="clientTable"
                      :total-rows="totalRows"
                      :per-page="pageLength"
                      first-number
                      last-number
                      class="mt-1 mb-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @input="
                        value => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                Nenhum registro encontrado!
              </div>
            </vue-good-table>
          </b-card-actions>
        </b-card>
        <DeleteModal
          :id="selectedClientId"
          ref="modal"
          @inactivateClient="updateModal"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect
} from "bootstrap-vue"
import vSelect from "vue-select"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import { onUnmounted } from "@vue/composition-api"
import { VueGoodTable } from "vue-good-table"
import Ripple from "vue-ripple-directive"

import store from "@/store"
import clietnStoreModule from "./clientStoreModule"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import DeleteModal from "./modal-delete-user.vue"

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardActions,
    BRow,
    BCol,
    BFormInput,
    BButton,
    // BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BBadge,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BPagination,

    // vSelect,
    DeleteModal
  },
  directives: { Ripple, "b-tooltip": VBTooltip },

  props: {
    selectedFilter: {
      type: String,
      required: false,
      default: ""
    },
    selectedStatusFilter: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-client"

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, clietnStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })
  },
  data() {
    return {
      selectedClientId: 0,
      pageLength: 5,
      dir: false,
      columns: [
        {
          field: "nomeDoCliente",
          label: "Cliente",
          sortable: true
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger',
        },
        {
          field: "tipoDoCliente",
          label: "Tipo",
          sortable: true
        },
        {
          field: "documentoDoCliente",
          label: "CPF/CNPJ",
          sortable: true,
          formatFn: this.formatFn

          // Variant applies to the whole column, including the header and footer
          // variant: "danger"
        },
        {
          field: "telefoneDoCliente",
          label: "Telefone",
          // sortable: true,
          formatFn: this.formatFnTel
          // variant: 'danger',
        },
        {
          field: "statusDoCliente",
          label: "Status"
          // sortable: true,
          // variant: 'danger',
        },
        {
          field: "action",
          label: "Ações"
          // sortable: true,
          // variant: 'danger',
        }
      ],
      searchQuery: ""
    }
  },
  computed: {
    getClients() {
      return this.$store.getters["app-client/getClients"](
        this.searchQuery,
        this.selectedFilter,
        this.selectedStatusFilter
      )
    },
    dataMeta() {
      const count = this.getClients.length
      const auxTo = this.pageLength * (this.currentPage - 1) + this.pageLength

      return {
        from: this.pageLength * (this.currentPage - 1) + 1,
        to: auxTo > count ? count : auxTo,
        of: this.getClients.length
      }
    },
    totalRows() {
      return this.getClients.length
    }
  },
  mounted() {
    // this.$refs["loadingCard"].showLoading = true
    this.newFetchUser()
  },
  methods: {
    formatFn: function(documentoDoCliente) {
      if (documentoDoCliente.length <= 11) {
        return (documentoDoCliente = this.mascaraCpf(documentoDoCliente))
      } else {
        return (documentoDoCliente = this.mascaraCnpj(documentoDoCliente))
      }
    },
    retirarFormatacao(valor) {
      valor = valor.replace(/(\.|\/|\-)/g, "")
    },
    mascaraCpf(valor) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")
    },
    mascaraCnpj(valor) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "\$1.\$2.\$3\/\$4\-\$5"
      )
    },
    // formatFnTel: function(telefoneDoCliente) {
    //   if (telefoneDoCliente.length <= 10) {
    //     return (telefoneDoCliente = this.mascaraTelFixo(telefoneDoCliente))
    //   } else {
    //     return (telefoneDoCliente = this.mascaraTelCel(telefoneDoCliente))
    //   }
    // },
    // mascaraTelFixo(valor) {
    //   return valor.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
    // },
    // mascaraTelCel(valor) {
    //   return valor.replace(/^\([0-9]{2}\) [0-9]{5}-[0-9]{4}$/)
    // },

    formatFnTel: function(telefoneDoCliente) {
      var r = telefoneDoCliente.replace(/\D/g, "")
      r = r.replace(/^0/, "")
      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
      } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3")
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2")
      } else {
        r = r.replace(/^(\d*)/, "($1")
      }
      return r
    },

    async newFetchUser() {
      await this.$store.dispatch("app-client/newfetchUsers")
    },
    addClient() {
      this.$router.push({ name: "client-register" })
    },
    handleSelectedId(id) {
      this.selectedClientId = id
      this.$bvModal.show("modal-delete")
    },
    updateModal() {
      this.newFetchUser()
    },
    showModalActive(id) {
      this.$bvModal
        .msgBoxConfirm("Deseja Ativar esse cliente?", {
          title: "Confirmar a Ativação",
          size: "sm",
          okVariant: "primary",
          okTitle: "SIM",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          // aqui vai o codigo para delete ou cancel
          if (value == true) {
            this.ativarClient(id)
          }
        })
    },
    async ativarClient(id) {
      this.selectedClientId = id
      if (this.selectedClientId != 0) {
        const id = this.selectedClientId
        const resp = await useJwt
          .ativarClientById(id)
          .then(response => response.data)
          .catch(response => {
            const catchError = Object.assign({}, response)
            this.MensagemToastErrorAtivarCliente(
              "danger",
              "top-center",
              catchError.response.status
            )
          })
        if (resp.sucesso) {
          this.MensagemToastAtivarCliente("success", "top-center")
          setTimeout(() => {
            this.newFetchUser()
          }, 1000)
        }
      } else {
        return this.MensagemToastPermissaoAtivarCliente("warning", "top-center")
      }
    },

    showModalRemove(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Ao excluir o cliente você apagará permanentemente todos os dados e registros relacionados a ele do sistema. Você confirma a exclusão?",
          {
            title: "Confirmar a exclusão",
            size: "sm",
            okVariant: "primary",
            okTitle: "SIM",
            cancelTitle: "Cancelar",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          // aqui vai o codigo para delete ou cancel
          if (value == true) {
            this.removeClient(id)
          }
        })
    },
    async removeClient(id) {
      this.selectedClientId = id
      if (this.selectedClientId != 0) {
        const id = this.selectedClientId
        const resp = await useJwt
          .removeClientById(id)
          .then(response => response.data)
          .catch(response => {
            const catchError = Object.assign({}, response)
            this.MensagemToastErrorRemoverCliente(
              "danger",
              "top-center",
              catchError.response.status
            )
          })
        if (resp.sucesso) {
          this.MensagemToastRemoverCliente("success", "top-center")
          setTimeout(() => {
            this.newFetchUser()
          }, 1000)
        }
      } else {
        return this.MensagemToastPermissaoRemoverCliente(
          "warning",
          "top-center"
        )
      }
    },
    MensagemToastErrorAtivarCliente(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao Ativar Cliente, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastAtivarCliente(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Cliente Ativado com sucesso!",
            icon: "EditIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemToastPermissaoAtivarCliente(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Desculpe, você não tem permissão para Ativar esse Cliente!",
            icon: "CoffeeIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemToastErrorRemoverCliente(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao Excluir Cliente, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemToastRemoverCliente(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Cliente excluído com sucesso!",
            icon: "EditIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemToastPermissaoRemoverCliente(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Desculpe, você não tem permissão para excluir esse Cliente!",
            icon: "CoffeeIcon",
            variant
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.icone {
  color: inherit;
  margin-left: 6%;
}
.espaco {
  padding-left: 0% !important;
  margin-left: 0% !important;
}

@media only screen and (max-width: 1450px) {
  .espaco {
    padding-left: 5% !important;
    margin-left: -18% !important;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
