<template>
  <div class="client-filter">
    <ClientFilter
      @filter="handleFilter"
      @filterStatus="handleStatusFilter"
    />
    <ClientFormList
      :selected-filter="filter"
      :selected-status-filter="filterStatus"
    />
  </div>
</template>

<script>
import ClientFilter from "../components/client/client-filter.vue"
import ClientFormList from "../components/client/client-list.vue"

export default {
  components: {
    ClientFilter,
    ClientFormList
  },
  data() {
    return {
      filter: "",
      filterStatus: ""
    }
  },
  methods: {
    handleFilter(userFilter) {
      if (userFilter && userFilter.value !== null) {
        this.filter = userFilter.value
      } else {
        this.filter = ""
      }
    },
    handleStatusFilter(userFilterStatus) {
      if (userFilterStatus && userFilterStatus.value !== null) {
        this.filterStatus = userFilterStatus.value
      } else {
        this.filterStatus = ""
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
