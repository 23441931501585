import axios from '@axios'
import { baseUrl } from '@/constants'
import { removeAcento } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    getClients: (state) => (searchQuery, filter, filterStatus) =>
      searchQuery !== '' || filter !== '' || filterStatus !== ''
        ? state.users.filter((user) => {
          searchQuery = searchQuery.toLowerCase()
            return (
              (removeAcento(user.nomeDoCliente).includes(searchQuery) ||
                user.nomeDoCliente.toLowerCase().includes(searchQuery) ||
                user.documentoDoCliente.includes(searchQuery.replace(/(\.|\/|\-)/g,"")) ||
                user.tipoDoCliente.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchQuery.toLowerCase()) ||
                user.telefoneDoCliente.includes(searchQuery) ||
                user.statusDoCliente.includes(searchQuery) ||
                user.statusDoCliente.toLowerCase().includes(searchQuery.toLowerCase())) &&
               (user.statusDoCliente.toLowerCase() === filterStatus.toLowerCase() || filterStatus === '') &&
                (user.tipoDoCliente.toLowerCase() === filter.toLowerCase() || filter === '')
            )
          })
        : state.users,
  },
  mutations: {
    SAVE_USER(state, users) {
      state.users = users
    },
  },
  actions: {
    newfetchUsers({ commit }) {
      return new Promise((reject) => {
        axios
          .get(
            // 'http://serv2.fmxsolucoes.com.br:33110/api/usuario/listar-cliente'
            `${baseUrl}/api/usuario/listar-cliente`
          )
          .then((response) => {
            commit('SAVE_USER', response.data.dados.usuarios)
          })
          .catch((error) => reject(error))
      })
    },
  },
}
